/**
 * Add the correct attributes and update the checkboxes what are generated by SurveyJS, so it's conform WCAG 2.1
 *
 * @param event
 * @param inputTypeCheckboxes
 */
const accessibleCheckboxAriaChecked = (event: Event, inputTypeCheckboxes: NodeListOf<HTMLInputElement>): void => {
  const inputTypeCheckboxChecked = event.currentTarget as HTMLInputElement;
  const inputTypeCheckboxesArray = Array.from(inputTypeCheckboxes) as [HTMLInputElement];
  // check if selectAll exist in the question group
  const selectAll = inputTypeCheckboxesArray.find((inputTypeCheckbox) => inputTypeCheckbox.value === 'selectall');
  // update the current input checkbox aria-checked with the correct state
  inputTypeCheckboxChecked.setAttribute('aria-checked', `${inputTypeCheckboxChecked.checked}`);
  // if the checkbox has the value selectall, all checkboxes need to have the same state as selectall
  if (inputTypeCheckboxChecked.value === 'selectall') {
    inputTypeCheckboxes.forEach((inputTypeCheckbox) => {
      inputTypeCheckbox.setAttribute('aria-checked', `${inputTypeCheckboxChecked.checked}`);
    });
  } else if (selectAll !== undefined) {
    // find the states of all the other checkboxes and if every checkboxs is checked it will return true and update the selectAll element
    const inputTypeCheckboxesChecked = inputTypeCheckboxesArray
      .filter((inputTypeCheckbox) => inputTypeCheckbox.value !== 'selectall')
      .every((inputTypeCheckbox) => inputTypeCheckbox.checked === true);

    if (inputTypeCheckboxesChecked) {
      selectAll.setAttribute('aria-checked', 'true');
    } else {
      selectAll.setAttribute('aria-checked', 'false');
    }
  }
};

export default accessibleCheckboxAriaChecked;
