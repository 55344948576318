import { SurveyModel } from 'survey-react';
import { IOnAfterRenderPanelOptions } from '../ìnterfaces/OnAfterRenderPanelOptions';
import { SURVEYJS_PANEL_CONFIG } from '../constants/constants';

/**
 * The event is fired right after a panel is rendered in DOM. Use it to modify HTML elements.
 * `sender` - the survey object that fires the event, see more here about the [surveymodel]{@link https://surveyjs.io/Documentation/Library?id=surveymodel}
 * `options.panel` - a panel object for which the event is fired, see more here about the [panelmodel]{@link https://surveyjs.io/Documentation/Library?id=panelmodel}
 * `options.htmlElement` - an HTML element bound to the panel object
 *
 * default htmlElement expanded example
 * ```html
 * <h4 class="sv_p_title sv_p_title_expandable">
 *   <span>{{ title text }}</span>
 *   <span class="sv_panel_icon sv_expanded" tabindex="0" aria-expanded="true" aria-controls="{{ id expanded elenement }}"></span>
 * </h4>
 * <div id="{{ id expanded elenement }}" style="display: block;">
 *   ...
 * </div>
 * ```
 * deafult htmlElement collapsed example
 * ```html
 * <h4 class="sv_p_title sv_p_title_expandable">
 *   <span>{{ title text }}</span>
 *   <span class="sv_panel_icon" tabindex="0" aria-expanded="false"></span>
 * </h4>
 * <div id="{{ id expanded elenement }}" style="display: none;">
 *  ...
 * </div>
 * ```
 *
 * There are some restrictions in manipulating the DOM. We can't restruct the DOM tree, only modify the current elements.
 * So the prefered solution as described is on [w3]{@link https://www.w3.org/TR/wai-aria-practices-1.1/examples/accordion/accordion.html} is not possible
 *
 * Final solution
 * ```html
 * <h4 class="sv_p_title sv_p_title_expandable" aria-expanded="true">
 *   <span>Bewegen</span>
 *   <span class="sv_panel_icon" tabindex="0" role="img" aria-label="Up Arrow" aria-controls="{{ id expanded elenement }}"></span>
 * </h4>
 * <div id="{{ id expanded elenement }}" style="display: none;">
 *  ...
 * </div>
 * ```
 * There are some issues with this solution:
 * h4: The attribute aria-expanded is not supported by the role heading. This role is implicit on the element h4.
 * span.sv_panel_icon: `tabIndex` should only be declared on interactive elements.
 *
 * @param sender
 * @param options
 */
const onAfterRenderPanelHandler = (sender: SurveyModel, options: IOnAfterRenderPanelOptions): void => {
  const h4Element = options.htmlElement.querySelector('h4');
  const spanElements = options.htmlElement.querySelectorAll('span');
  const { isExpanded } = options.panel;
  const { audience } = SURVEYJS_PANEL_CONFIG;

  if (h4Element !== null) {
    h4Element.setAttribute('aria-expanded', `${isExpanded}`);
  }
  if (options.panel.data && options.panel.data.getValue(audience)) {
    sender.setValue(audience, options.panel.data.getValue(audience));
  }

  spanElements.forEach((element: HTMLElement) => {
    if (element.classList.contains('sv_panel_icon')) {
      element.removeAttribute('aria-expanded');
      element.setAttribute('role', 'img');
      if (isExpanded) {
        element.setAttribute('aria-label', 'Up Arrow');
      } else {
        element.setAttribute('aria-label', 'Down Arrow');
      }
    }
  });
};

export default onAfterRenderPanelHandler;
