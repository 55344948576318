import accessibleRemoveAttributeByName from './accessibleRemoveAttributeByName';
import accessibleRemoveTitleAttribute from './accessibleRemoveTitleAttribute';

/**
 * Modify the SurveyJS radio group conform WCAG 2.1
 * - Remove the title from label text
 * - Remove the aria-labelledby and aria-label from the element so it's not read multiple times with the screen reader
 * - Add legend in the fieldset with the aria-label from the title
 *
 * @param htmlElement SurveyJS question model html element
 */
const accessibleRadioGroup = (htmlElement: HTMLElement, id?: string):void => {
  const radioTitle = htmlElement.querySelector('h5');
  const radioTitleAriaLabel = radioTitle?.getAttribute('aria-label') || '';
  const radioItems = htmlElement.querySelectorAll('.c-radio__item');
  radioItems.forEach((radioItem) => {
    accessibleRemoveTitleAttribute(radioItem as HTMLElement);
  });

  const fieldset = htmlElement.querySelector('fieldset');
  const legend = document.createElement('legend');
  legend.setAttribute('aria-label', radioTitleAriaLabel);

  fieldset?.prepend(legend);
  if (id) {
    fieldset?.setAttribute('aria-describedby', `${id}-collapse-helptext`);
  }

  accessibleRemoveAttributeByName(htmlElement, 'aria-labelledby');
  accessibleRemoveAttributeByName(radioTitle, 'aria-label');
};

export default accessibleRadioGroup;
