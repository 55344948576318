import { SurveyModel } from 'survey-react';
import { IOnUpdatePanelCssClassesOptions } from '../ìnterfaces/OnUpdatePanelCssClassesOptions';

/**
 * This function is bind to special SurveyJS event, the event is fired before rendering a panel.
 * This event is to override the default [panel CSS classes]{@link https://surveyjs.io/Documentation/Library/?id=surveymodel#onUpdatePanelCssClasses}.
 *
 * @param sender
 * @param options
 */
const onUpdatePanelCssClassesHandler = (sender: SurveyModel, options: IOnUpdatePanelCssClassesOptions): void => {
  const { cssClasses } = options;

  cssClasses.panel.title += ' c-panel__title';
};

export default onUpdatePanelCssClassesHandler;
