import React, { ReactElement } from 'react';
import { DATA_TABLE_CELL_TEXT, LANGUAGE } from '../../constants/constants';
import { IResultTableDataCellProps } from '../../ìnterfaces/ResultTableDataCellProps';

/**
 * Standard data cell of an result table
 *
 * @param props
 * @returns
 */
function ResultTableDataCell(props: IResultTableDataCellProps): ReactElement {
  const { cell, onClickHandler } = props;
  const { value } = cell;
  const valueTextArr = DATA_TABLE_CELL_TEXT[LANGUAGE];

  return (
    <td
      data-testid="td"
      tabIndex={-1}
      role="gridcell"
      className={`rating-${value}`}
      onClick={onClickHandler}
    >
      <span dangerouslySetInnerHTML={{ __html: valueTextArr[value] }} />
    </td>
  );
}

export default ResultTableDataCell;
