import { ICssClassesRadio } from '../ìnterfaces/CssClassesRadio';

/**
 * Update the predefined elements with additional classes for the radio tiles styling
 *
 * @param cssClasses all the predefined SurveyJS radio css classes
 */
const updateRadioCssClasses = (cssClasses: ICssClassesRadio): void => {
  cssClasses.mainRoot += ' c-radio';
  cssClasses.title += ' c-radio__title';
  cssClasses.description += ' c-radio__description c-description';
  cssClasses.item += ' c-radio__item';
  cssClasses.label += ' c-radio__label';
  cssClasses.materialDecorator += ' c-radio__decorator';
  cssClasses.controlLabel += ' c-radio__label__text';
};

export default updateRadioCssClasses;
