/**
 * Replace anchors in table so it's not
 *
 * @param html
 * @returns
 */
const accessibleHtmlDownloadFile = (html:HTMLElement): HTMLElement => {
  // replace the anchors in the table with role grid with a span
  const anchorsInGrid = html.querySelectorAll('[role="grid"] a');
  anchorsInGrid.forEach((anchor) => {
    const spanContainer = document.createElement('span');
    spanContainer.classList.add('anchor-replace');
    anchor.querySelectorAll('span').forEach((span) => {
      spanContainer.appendChild(span);
    });
    anchor.parentNode?.replaceChild(spanContainer, anchor);
  });

  // delete tabindex in the table with role grid
  const tabindexesInGrid = html.querySelectorAll('[role="grid"] [tabindex]');
  tabindexesInGrid.forEach((tabindex) => {
    tabindex.removeAttribute('tabindex');
  });

  // delete role="gridcell" in the table with role grid
  const gridcellRolesInGrid = html.querySelectorAll('[role="grid"] [role="gridcell"]');
  gridcellRolesInGrid.forEach((gridcell) => {
    gridcell.removeAttribute('role');
  });

  // delete data-testid in the table with role grid
  const dataTestids = html.querySelectorAll('[role="grid"] [data-testid]');
  dataTestids.forEach((dataTestid) => {
    dataTestid.removeAttribute('data-testid');
  });

  // delete the role="grid" from the table
  html.querySelector('[role="grid"]')?.removeAttribute('role');

  return html;
};

export default accessibleHtmlDownloadFile;
