/**
 * Search the title and remove it
 *
 * @param element html element
 */
const accessibleRemoveTitleAttribute = (element: HTMLElement): void => {
  const elementTitle = element.querySelector('span[title]');
  elementTitle?.removeAttribute('title');
};

export default accessibleRemoveTitleAttribute;
