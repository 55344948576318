import { DEFAULT_APPLICATION_TEXT, LANGUAGE } from '../constants/constants';
import { IQuestionCheckboxModel } from '../ìnterfaces/QuestionCheckboxModel';
import { IQuestionRadiogroupModel } from '../ìnterfaces/QuestionRadiogroupModel';

const { REQUIRED_ERROR_TEXT } = DEFAULT_APPLICATION_TEXT[LANGUAGE];

/**
 * Make all the required error text for all the questions conform WCAG 2.1
 *
 * @param question
 */
const accessibleRequiredErrorText = (question: IQuestionCheckboxModel | IQuestionRadiogroupModel): void => {
  const { requiredErrorText: errorText, title } = question.localizableStrings;

  if (!errorText.values[LANGUAGE] && !errorText.values.default) {
    const requiredError = `"${title.values[LANGUAGE] || title.values.default}" ${REQUIRED_ERROR_TEXT}.`;
    errorText.values[LANGUAGE] = requiredError;
  }
};

export default accessibleRequiredErrorText;
