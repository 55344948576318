import React, { ReactElement } from 'react';
import { IResultTableBodyProps } from '../../ìnterfaces/ResultTableBodyProps';
import ResultTableRows from './ResultTableRows';

/**
 * Group the body content in an result table and contains table rows
 *
 * @param props
 * @returns
 */
function ResultTableBody(props: IResultTableBodyProps): ReactElement {
  const { rows, onClickHandler } = props;
  const tableRows = rows.map((row, index) => <ResultTableRows rowIndex={index} row={row} key={row.name} onClickHandler={onClickHandler} />);

  return (
    <tbody className="c-table__tbody" data-testid="tbody">
      { tableRows }
    </tbody>
  );
}

export default ResultTableBody;
