import React from 'react';
import { renderToString } from 'react-dom/server';
import DropdownButtonComponent from '../components/dropdownButtonComponent/DropdownButtonComponent';
import DropdownDescriptionComponent from '../components/dropdownDescriptionComponent/DropdownDescriptionComponent';

const buttonEventHandler = (currentTarget: HTMLButtonElement, parentElement: HTMLElement | null | undefined) => {
  currentTarget.classList.toggle('collapsed');
  currentTarget.setAttribute('aria-expanded', (!(currentTarget.getAttribute('aria-expanded') === 'true')).toString());
  parentElement?.classList.toggle('collapsed');
};

/**
 * Also it will bind a click event to the new button in the modified HTML, what will toggle between hiding or showing the description text.
 * In the method there are some extra HTML elements added so the description of the question is placed behind a button.
 *
 * @param htmlElement SuveryJS question model html element
 * @param id unique SuveryJS question id
 * @param description description to hide
 */
const toggleShowHideQuestionDescription = (htmlElement: HTMLElement, id: string, description: string): void => {
  const uniqueId = `${id}-collapse-helptext`;
  const h5Element = htmlElement.querySelector('h5');
  const smallElement = htmlElement.querySelector('.small');
  const parentElement = h5Element?.parentElement;
  const htmlStringButton = renderToString(<DropdownButtonComponent id={uniqueId} />);
  const htmlStringDescription = description ? renderToString(<DropdownDescriptionComponent id={uniqueId} description={description} />) : '';

  smallElement?.remove();
  h5Element?.insertAdjacentHTML('afterend', htmlStringDescription);
  h5Element?.insertAdjacentHTML('afterend', htmlStringButton);
  parentElement?.classList.add('d-flex', 'c-dropdown-description', 'flex-wrap');

  const btn = htmlElement.querySelector('.js-dropdown-description-button');

  if (btn !== null) {
    const btnElement = btn as HTMLButtonElement;

    btnElement.addEventListener('keydown', (event:KeyboardEvent) => {
      const { key } = event;

      if (key === 'Enter' || key === ' ') {
        event.preventDefault();

        const currentTarget = event.currentTarget as HTMLButtonElement;
        buttonEventHandler(currentTarget, parentElement);
      }
    });

    btnElement.addEventListener('click', (event: Event) => {
      const currentTarget = event.currentTarget as HTMLButtonElement;
      buttonEventHandler(currentTarget, parentElement);
    });
  }
};

export default toggleShowHideQuestionDescription;
