import { RefObject } from 'react';

/**
 * Find the current "tabindex" 0 in the table and change it to -1
 * And change the current element to 0
 * This is needed to get the tab navigation correct in a grid conform WCAG 2.1
 *
 * @param currentElement
 * @param tableRef
 */
const handleResultTableTabIndex = (currentElement: HTMLElement, tableRef: RefObject<HTMLTableElement>): void => {
  const currentTabIndex = tableRef.current?.querySelector('[tabindex="0"]');
  if (currentTabIndex) (currentTabIndex as HTMLElement).tabIndex = -1;
  currentElement.tabIndex = 0;
};

export default handleResultTableTabIndex;
