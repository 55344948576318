import { DEFAULT_APPLICATION_TEXT, LANGUAGE } from '../constants/constants';
import accessibleHtmlDownloadFile from './accessibleHtmlDownloadFile';
import addStyleObjectsToDocument from './addStyleObjectsToDocument';
import addStylesheetsAsStyleObjectToDocument from './addStylesheetsAsStyleObjectToDocument';

/**
 * Creates a HMTL DOM document and with the HTML it receives from the parameter.
 * It will add all the style sheets from the active DOM document as style objects in the head of the HTML DOM document.
 * It will add all the style objects from the active DOM document in the head of the HTML DOM document.
 * It removes the  btn-row what is set in the received HTML element
 *
 * @param downloadHtml
 * @returns
 */
const createHtmlDownloadFile = async (downloadHtml:HTMLElement): Promise<string> => {
  const { DOWNLOAD_TITLE: DOWNLOAD_HTML_TITLE } = DEFAULT_APPLICATION_TEXT[LANGUAGE];
  const parser = new DOMParser();
  const accessibleHtml = accessibleHtmlDownloadFile(downloadHtml);
  const newDocument = parser.parseFromString(`
  <div class="container is-print">
    <div class="row">
      <div class="col">${accessibleHtml.outerHTML}</div>
    </div>
  </div>`, 'text/html');

  // add stylesheet objects
  await addStylesheetsAsStyleObjectToDocument(newDocument);
  // add style objects
  addStyleObjectsToDocument(newDocument);
  // remove button download result as HTML from the HTML document
  newDocument.querySelector('.btn-row')?.remove();
  // add the charset so the special charters will be properly showed
  const meta = document.createElement('meta') as HTMLMetaElement;
  meta.setAttribute('charset', 'utf-8');
  newDocument.head.appendChild(meta);
  // add the title for the HTML document conform WCAG 2.1
  const title = document.createElement('title') as HTMLTitleElement;
  title.appendChild(document.createTextNode(DOWNLOAD_HTML_TITLE));
  newDocument.head.appendChild(title);
  // add lang tag to the html element
  const html = newDocument.querySelector('html') as HTMLElement;
  html.setAttribute('lang', LANGUAGE);

  return Promise.resolve(newDocument.documentElement.outerHTML);
};

export default createHtmlDownloadFile;
