import { SurveyModel } from 'survey-react';
import { IOnUpdatePageCssClassesOptions } from '../ìnterfaces/onUpdatePageCssClassesOptions';

/**
 * This function is bind to special SurveyJS event, the event is fired before rendering a page.
 * This event is to override the default [page CSS classes]{@link https://surveyjs.io/Documentation/Library/?id=surveymodel#onUpdatePageCssClasses}.
 *
 * @param sender
 * @param options
 */
const onUpdatePageCssClassesHandler = (sender: SurveyModel, options: IOnUpdatePageCssClassesOptions): void => {
  const { cssClasses } = options;

  cssClasses.row += ' c-panel';
};

export default onUpdatePageCssClassesHandler;
