import React, { ReactElement } from 'react';

/**
 * Renders the text element with the description of a question
 *
 * @param props
 * @returns
 */
function DropdownDescriptionComponent(props: { id: string, description:string }): ReactElement {
  const { id, description } = props;

  return (
    <div
      className="collapse c-dropdown-description__text"
      data-testid="description"
      id={id}
    >
      <p>
        { description }
      </p>
    </div>
  );
}

export default DropdownDescriptionComponent;
