import addStyleObjectToHead from './addStyleObjectToHead';

/**
 * Get all the style objects from the current Document and add it to the download document.
 */
const addStyleObjectsToDocument = (newDocument:HTMLDocument): void => {
  const styleElements = document.querySelectorAll('style');

  styleElements.forEach((styleElement) => {
    if (styleElement.innerHTML.length > 0) {
      addStyleObjectToHead(newDocument, styleElement.innerHTML);
    }
  });
};

export default addStyleObjectsToDocument;
