/**
 * Get the specific JSON from Drupal based on the JSON path
 * Example of the drupal URL: '{domain}/sites/default/files/{surveyJsonPath}'
 * surveyJsonPath: '2021-08/cgl-primary-school-survey.json'
 *
 * @param jsonPath
 * @returns
 */
const getJsonFromDrupal = async <T>(jsonPath: string): Promise<T> => {
  const response = await fetch(`/sites/default/files/${jsonPath}`);
  return response.json();
};

export default getJsonFromDrupal;
