import React, { ReactElement } from 'react';

/**
 * Renders the button to toggle the description for a question
 *
 * @param props
 * @returns
 */
function DropdownButtonComponent(props: { id: string }): ReactElement {
  const { id } = props;

  return (
    <button
      type="button"
      className="btn btn-primary c-dropdown-description__button js-dropdown-description-button collapsed"
      data-testid="button"
      data-toggle="collapse"
      data-target={`#${id}`}
      aria-expanded="false"
      aria-controls={id}
      aria-label="Helptekst weergeven/verbergen"
      aria-describedby={id}
    >
      ?
    </button>
  );
}

export default DropdownButtonComponent;
