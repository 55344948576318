import { ICssClassesCheckbox } from '../ìnterfaces/CssClassesCheckbox';

/**
 * Update the predefined elements with additional classes for the checkbox tiles styling
 *
 * @param cssClasses all the predefined SurveyJS checkbox css classes
 */
const updateCheckboxCssClasses = (cssClasses: ICssClassesCheckbox): void => {
  cssClasses.mainRoot += ' c-checkbox';
  cssClasses.header += ' c-checkbox__header';
  cssClasses.title += ' c-checkbox__title';
  cssClasses.description += ' c-checkbox__description c-description';
  cssClasses.root += ' c-checkbox__group d-flex';
  cssClasses.item += ' c-checkbox__item';
  cssClasses.itemSelectAll += ' c-checkbox__item--select-all';
  cssClasses.label += ' c-checkbox__label';
  cssClasses.materialDecorator += ' c-checkbox__decorator';
  cssClasses.controlLabel += ' c-checkbox__label__text d-flex align-items-center';
};

export default updateCheckboxCssClasses;
