import React, { ReactElement, useEffect, useState } from 'react';
import * as Survey from 'survey-react';
import { ISurvey, SurveyModel } from 'survey-react';
import * as showdown from 'showdown';

import getJsonFromDrupal from '../../utils/getJsonFromDrupal';
import onAfterRenderPanelHandler from '../../utils/onAfterRenderPanelHandler';
import onAfterRenderQuestionHandler from '../../utils/onAfterRenderQuestionHandler';
import onCompleteHandler from '../../utils/onCompleteHandler';
import onTextMarkDownHandler from '../../utils/onTextMarkDownHandler';
import onUpdatePageCssClassesHandler from '../../utils/onUpdatePageCssClassesHandler';
import onUpdatePanelCssClassesHandler from '../../utils/onUpdatePanelCssClassesHandler';
import onUpdateQuestionCssClassesHandler from '../../utils/onUpdateQuestionCssClassesHandler';

import { ConfigType } from '../../types/ConfigType';
import { IOnCompleteOptions } from '../../ìnterfaces/OnCompleteOptions';
import { IOnTextMarkDownOptions } from '../../ìnterfaces/OnTextMarkDownOptions';

import SURVEYJS_CONFIG, { DEFAULT_APPLICATION_TEXT, LANGUAGE } from '../../constants/constants';

import './surveyComponent.scss';

Survey.StylesManager.applyTheme('bootstrap');
Survey.surveyLocalization.locales.nl.requiredError = 'Er zijn één of meerdere velden niet ingevuld';

/**
 * SurveyComponent get the SurveyJS Model JSON from web API
 * and build the Survey and render it in the DOM
 */
function SurveyComponent(props: { matomoSiteId: string, configJsonPath: string, surveyJsonPath: string }): ReactElement {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState({});
  const [config, setConfig] = useState({});

  const { matomoSiteId, configJsonPath, surveyJsonPath } = props;
  const converter = new showdown.Converter();

  /**
   * Add the config in the onComplete event
   *
   * @param sender
   * @param options
   */
  const onCompleteHandlerBind = (sender: SurveyModel, options: IOnCompleteOptions): void => {
    onCompleteHandler(sender, options, config, matomoSiteId);
  };

  /**
   * Add the showdown converter in the onTextMarkdown event
   *
   * @param sender
   * @param options
   */
  const onTextMarkDownBind = (sender: SurveyModel, options: IOnTextMarkDownOptions): void => {
    onTextMarkDownHandler(sender, options, converter);
  };

  /**
   * Render the Survey in the DOM based on the Model JSON
   */
  const renderSurvey = () => {
    const { css } = SURVEYJS_CONFIG;
    const survey = new Survey.Model(data);

    survey.showQuestionNumbers = 'off';
    survey.locale = LANGUAGE;
    survey.onUpdatePageCssClasses.add(onUpdatePageCssClassesHandler);
    survey.onUpdateQuestionCssClasses.add(onUpdateQuestionCssClassesHandler);
    survey.onUpdatePanelCssClasses.add(onUpdatePanelCssClassesHandler);
    survey.onAfterRenderPanel.add(onAfterRenderPanelHandler);
    survey.onAfterRenderQuestion.add(onAfterRenderQuestionHandler);
    survey.onComplete.add(onCompleteHandlerBind);
    survey.onTextMarkdown.add(onTextMarkDownBind);

    return (
      <div className="text-left">
        <Survey.Survey
          model={survey}
          css={css}
          showCompletedPage={false}
        />
        <div id="survey-result" />
      </div>
    );
  };

  /**
   * Perform side effects in function components.
   * In this way it handle async functionality
   */
  useEffect(() => {
    if (loading) {
      const fetchData = async () => {
        try {
          const configJson = await getJsonFromDrupal<ConfigType>(configJsonPath);
          setConfig(configJson);
          const surveyJson = await getJsonFromDrupal<ISurvey>(surveyJsonPath);
          setData(surveyJson);
        } catch (err) {
          setError(true);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [loading, matomoSiteId, configJsonPath, surveyJsonPath]);

  if (error) {
    return (
      <div>{DEFAULT_APPLICATION_TEXT[LANGUAGE].ERROR_MESSAGE}</div>
    );
  }

  return (
    <div>
      { loading ? DEFAULT_APPLICATION_TEXT[LANGUAGE].LOADING_MESSAGE : renderSurvey() }
    </div>
  );
}

export default SurveyComponent;
