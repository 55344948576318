import { ICssClasses } from '../ìnterfaces/CssClasses';
import { IDataTableCellTranslationObject } from '../ìnterfaces/DataTableCellTranslationObject';
import { IDefaultApplicationMessagesTranslationObject } from '../ìnterfaces/DefaultApplicationMessagesTranslationObject';

const DATA_TABLE_CELL_TEXT = {
  en: {
    1: 'Start?',
    2: 'Need&nbsp;help?',
    3: 'Good&nbsp;job!',
  },
  nl: {
    1: 'Starten?',
    2: 'Hulp&nbsp;nodig?',
    3: 'Goed&nbsp;bezig!',
  },
} as IDataTableCellTranslationObject;

const DEFAULT_APPLICATION_TEXT = {
  en: {
    BTN_SAVE_HTML: 'Save as HTML',
    BTN_SAVE_PDF: 'Save as PDF',
    CHECKBOX_REQUIRED_ARIA_LABEL: 'you must select at least one checkbox',
    DOWNLOAD_PDF_FILE_NAME: 'result-do-the-test.pdf',
    DOWNLOAD_HTML_FILE_NAME: 'result-do-the-test.html',
    DOWNLOAD_HTML_TITLE_NAME: 'Download HTML',
    DOWNLOAD_PDF_TITLE_NAME: 'Download PDF',
    DOWNLOAD_TITLE: 'Result do the test',
    ERROR_MESSAGE: 'An error has occurred while loading the survey',
    EXTERNAL_LINK_SR_ONLY: 'opens in a new tab',
    LOADING_MESSAGE: 'Loading survey',
    REQUIRED_ERROR_TEXT: 'is a required field, but not selected',
    BTN_RELOAD_TEST: 'Take the test again',
  },
  nl: {
    BTN_SAVE_HTML: 'Opslaan als HTML',
    BTN_SAVE_PDF: 'Opslaan als PDF',
    CHECKBOX_REQUIRED_ARIA_LABEL: 'u moet ten minste één selectievakje selecteren',
    DOWNLOAD_PDF_FILE_NAME: 'resultaat-doe-de-test.pdf',
    DOWNLOAD_HTML_FILE_NAME: 'resultaat-doe-de-test.html',
    DOWNLOAD_HTML_TITLE_NAME: 'Download HTML',
    DOWNLOAD_PDF_TITLE_NAME: 'Download PDF',
    DOWNLOAD_TITLE: 'Resultaat doe de test',
    ERROR_MESSAGE: 'Er is een fout opgetreden tijdens het laden van de test',
    EXTERNAL_LINK_SR_ONLY: 'opent in een nieuw tabblad',
    LOADING_MESSAGE: 'Bezig met laden van de test',
    REQUIRED_ERROR_TEXT: 'is een verplicht veld, maar niet geselecteerd',
    BTN_RELOAD_TEST: 'Doe de test opnieuw',
  },
} as IDefaultApplicationMessagesTranslationObject;

const LANGUAGE = document.documentElement.lang as string || 'nl';

const SURVEYJS_PANEL_CONFIG = {
  audience: 'wie_doet_de_test',
};

const SURVEYJS_CONFIG = {
  css: {
    root: 'sv_main sv_bootstrap_css c-main',
    body: 'panel-body card-block mt-4 c-body',
    footer: 'panel-footer card-footer c-body__footer',
    navigationButton: 'btn btn-lg',
    navigation: {
      complete: 'btn sv_complete_btn btn--complete',
      prev: 'btn btn-outline-dark prev-btn',
      next: 'btn btn-primary next-btn',
      start: 'btn btn-primary',
      preview: '',
      edit: '',
    },
    error: {
      root: 'alert alert-danger c-error',
      icon: 'glyphicon glyphicon-exclamation-sign c-error__icon',
    },
  } as ICssClasses,
};

export {
  DATA_TABLE_CELL_TEXT,
  DEFAULT_APPLICATION_TEXT,
  LANGUAGE,
  SURVEYJS_PANEL_CONFIG,
  SURVEYJS_CONFIG as default,
};
