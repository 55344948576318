import { RefObject } from 'react';
import handleResultTableTabIndex from './handleResultTableTabIndex';

/**
 * Set the focus on the correct element for the keyboard navigation in the result table
 * If it's the first element it should focus on the anchor element and not the th element
 *
 * @param cell
 * @param cellIndex
 * @param tableRef
 */
const handleResultTableFocus = (cell: HTMLTableDataCellElement, cellIndex: number, tableRef: RefObject<HTMLTableElement>): void => {
  if (cellIndex === 0) {
    const anchor = cell.querySelector('a') || cell;
    handleResultTableTabIndex(anchor, tableRef);
    anchor.focus();
  } else {
    handleResultTableTabIndex(cell, tableRef);
    cell.focus();
  }
};

export default handleResultTableFocus;
