import accessibleCheckboxAriaChecked from './accessibleCheckboxAriaChecked';
import accessibleRemoveAttributeByName from './accessibleRemoveAttributeByName';
import accessibleRemoveTitleAttribute from './accessibleRemoveTitleAttribute';
import { DEFAULT_APPLICATION_TEXT, LANGUAGE } from '../constants/constants';

/**
 * Modify the SurveyJS checkbox conform WCAG 2.1
 * - Modify the aria-label without the HMTL img tag what is added with Markdown
 * - Add the role attribute with checkbox as value
 * - Add the aria-checked attribute
 * - Bind change event to update the aria-checked attribute on user interaction
 * - Remove the title from label text
 * - Remove the aria-labelledby and aria-label from the element so it's not read multiple times with the screen reader
 * - Remove the aria-required from the checkbox items, because it's only
 *
 * @param htmlElement SurveyJS question model html element
 */
const accessibleCheckbox = (htmlElement: HTMLElement): void => {
  const checkboxTitle = htmlElement.querySelector('h5') as HTMLHeadingElement;
  const checkboxLegend = htmlElement.querySelector('legend') as HTMLLegendElement;
  const checkboxItems = htmlElement.querySelectorAll('.c-checkbox__item') as NodeListOf<HTMLElement>;
  const inputTypeCheckboxes = htmlElement.querySelectorAll('input[type=checkbox]') as NodeListOf<HTMLInputElement>;
  const { CHECKBOX_REQUIRED_ARIA_LABEL } = DEFAULT_APPLICATION_TEXT[LANGUAGE];

  if (inputTypeCheckboxes[0].hasAttribute('aria-required')) {
    const currentAriaLabel = checkboxLegend.getAttribute('aria-label');
    checkboxLegend.setAttribute('aria-label', `${currentAriaLabel}, ${CHECKBOX_REQUIRED_ARIA_LABEL}`);
  }

  checkboxItems.forEach((checkboxItem) => {
    const inputTypeCheckbox = checkboxItem.querySelector('input[type=checkbox]') as HTMLInputElement;
    const ariaLabel = inputTypeCheckbox.getAttribute('aria-label') as string;
    const newAriaLabel = ariaLabel.replace(/<img[^>]*>/g, '').trim();

    accessibleRemoveAttributeByName(inputTypeCheckbox, 'aria-required');

    inputTypeCheckbox.setAttribute('aria-label', newAriaLabel);
    inputTypeCheckbox.setAttribute('aria-checked', `${inputTypeCheckbox.checked}`);
    inputTypeCheckbox.setAttribute('role', 'checkbox');
    inputTypeCheckbox.addEventListener('change', (event) => {
      accessibleCheckboxAriaChecked(event, inputTypeCheckboxes);
    });

    accessibleRemoveAttributeByName(htmlElement, 'aria-labelledby');
    accessibleRemoveAttributeByName(checkboxTitle, 'aria-label');
    accessibleRemoveTitleAttribute(checkboxItem);
  });
};

export default accessibleCheckbox;
