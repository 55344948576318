import addStyleObjectToHead from './addStyleObjectToHead';

/**
 * Get all the link objects with the rel value 'stylesheet' from the current Document.
 * And get the CSS from the stylesheet and add it as a style object to the download document.
 */
const addStylesheetsAsStyleObjectToDocument = async (newDocument:HTMLDocument): Promise<void> => {
  const linkElements = Array.from(document.querySelectorAll('link[rel="stylesheet"]')) as HTMLStyleElement[];

  const data = await linkElements.reduce(async (accumulator, linkElement) => {
    try {
      // This line will wait for the last async function to finish.
      // The first iteration uses an already resolved Promise so, it will immediately continue.
      await accumulator;
      const href = linkElement.getAttribute('href') as string;
      const response = await fetch(href);
      const css = await response.text();

      addStyleObjectToHead(newDocument, css);
    } catch {
      // gracefully handling errors, so it will still add the other stylesheets
      Promise.resolve();
    }
  }, Promise.resolve());

  return Promise.resolve(data);
};

export default addStylesheetsAsStyleObjectToDocument;
