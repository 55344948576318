import { SurveyModel } from 'survey-react';
import { ICssClassesCheckbox } from '../ìnterfaces/CssClassesCheckbox';
import { ICssClassesRadio } from '../ìnterfaces/CssClassesRadio';
import { OnUpdateQuestionCssClassesOptions } from '../ìnterfaces/OnUpdateQuestionCssClassesOptions';
import updateCheckboxCssClasses from './updateCheckboxCssClasses';
import updateRadioCssClasses from './updateRadioCssClasses';

/**
 * This function is bind to special SurveyJS event, the event is fired before rendering a question.
 * This event is to override the default [questions CSS classes]{@link https://surveyjs.io/Documentation/Library/?id=surveymodel#onUpdateQuestionCssClasses}.
 *
 * @param sender
 * @param options
 */
const onUpdateQuestionCssClassesHandler = (sender: SurveyModel, options: OnUpdateQuestionCssClassesOptions): void => {
  const { cssClasses } = options;
  const type = options.question.getType();

  if (type === 'checkbox') {
    updateCheckboxCssClasses(cssClasses as ICssClassesCheckbox);
  }

  if (type === 'radiogroup') {
    updateRadioCssClasses(cssClasses as ICssClassesRadio);
  }
};

export default onUpdateQuestionCssClassesHandler;
