import { Converter } from 'showdown';
import { SurveyModel } from 'survey-react';
import { IOnTextMarkDownOptions } from '../ìnterfaces/OnTextMarkDownOptions';

/**
 * This function is bind to special SurveyJS event, the event is fired before rendering the text.
 * This event is to process the [markdown text]{@link https://surveyjs.io/Documentation/Library/?id=surveymodel#onTextMarkdown}.
 *
 * @param sender
 * @param options
 * @param converter
 */
const onTextMarkDownHandler = (sender:SurveyModel, options:IOnTextMarkDownOptions, converter:Converter): void => {
  // convert the markdown text to html
  let str = converter.makeHtml(options.text);
  // remove root paragraphs <p></p>
  str = str.substring(3);
  str = str.substring(0, str.length - 4);
  // set html
  options.html = str;
};

export default onTextMarkDownHandler;
