/**
 * Couple the description text with the question
 * This is needed for the screen reader to understand what the purpose of this text is
 *
 * @param htmlElement
 * @param id
 */
const accessibleDescription = (htmlElement: HTMLElement, id: string): void => {
  const descriptionElement = htmlElement.querySelector('.c-description') as HTMLDivElement;
  const uniqueId = `${id}-description`;
  htmlElement.setAttribute('aria-describedby', uniqueId);
  descriptionElement.id = uniqueId;
};

export default accessibleDescription;
