import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  IPanel, SurveyModel, IElement,
} from 'survey-react';
import ResultComponent from '../components/resultComponent/ResultComponent';
import { SURVEYJS_PANEL_CONFIG } from '../constants/constants';
import { ConfigType } from '../types/ConfigType';
import { IConfigItem } from '../ìnterfaces/ConfigItem';
import { IOnCompleteOptions } from '../ìnterfaces/OnCompleteOptions';
import { IResultTable } from '../ìnterfaces/ResultTable';
import { IResultTableItem } from '../ìnterfaces/ResultTableItem';
import { IResultTableRow } from '../ìnterfaces/ResultTableRow';

/**
 * This function is bind to special SurveyJS event, the event is fired after the user clicks on the 'complete' button and finishes a survey.
 * This event is to process the data [on completion of the survey]{@link https://surveyjs.io/Documentation/Library/?id=surveymodel#onComplete}.
 * In the function it uses the [getAllPanels]{@link https://surveyjs.io/Documentation/Library/?id=surveymodel#getAllPanels} method, what returns a list af all survey's panels.
 *
 * @param sender
 * @param options
 * @param config
 * @param matomoId
 */
const onCompleteHandler = (sender: SurveyModel, options: IOnCompleteOptions, config: ConfigType, matomoId: string): void => {
  const instance = createInstance({
    urlBase: 'https://statistiek.rijksoverheid.nl/piwik/',
    siteId: +matomoId,
  });
  const {
    tableHeading1, tableHeading2, tableHeading3, tableHeading4,
  } = config;
  const result = {
    tableHeading1,
    tableHeading2,
    tableHeading3,
    tableHeading4,
    rows: [],
  } as IResultTable;

  const { audience } = SURVEYJS_PANEL_CONFIG;

  // loop through all the panels
  sender.getAllPanels().forEach((panel:IPanel, index: number) => {
    // only add a row when the panel is visible and has the correct index
    if (panel.isVisible && config.index <= index) {
      // get the link from de links JSON
      const linksAsKeyValuePairs = config as any;
      const { link, img, title } = linksAsKeyValuePairs[panel.name] as IConfigItem;
      // create a row object for the result table
      const items: IResultTableItem[] = [];
      const row: IResultTableRow = {
        name: panel.name,
        link,
        img,
        title,
        items,
      };
      // loop through all the questions in the panel
      panel.elements.forEach((element: IElement) => {
        const { name } = element;
        // if the name exist in the result json add it as an item to the table row
        if (sender.data[name]) {
          const item = {
            name,
            value: sender.data[name],
          };
          row.items.push(item);
        }
      });
      // add the row to the table
      result.rows.push(row);
    }
  });

  ReactDOM.render(
    <MatomoProvider value={instance}>
      <ResultComponent html={sender.completedHtml} audience={sender.getValue(audience)} result={result} />
    </MatomoProvider>,
    document.getElementById('survey-result'),
  );
};

export default onCompleteHandler;
