import React from 'react';
import ReactDOM from 'react-dom';
import SurveyComponent from './components/surveyComponent/SurveyComponent';
// import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('survey');

/**
 * Get the value from the data attribute on the React root element
 *
 * @returns
 */
const getDataValue = (name: string): string => rootElement?.dataset[name] || '';

ReactDOM.render(
  <React.StrictMode>
    <SurveyComponent
      matomoSiteId={getDataValue('matomoSiteId')}
      surveyJsonPath={getDataValue('surveyJson')}
      configJsonPath={getDataValue('configJson')}
    />
  </React.StrictMode>,
  rootElement,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://create-react-app.dev/docs/measuring-performance/
// reportWebVitals(console.log);
