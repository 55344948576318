import React, {
  ReactElement, useEffect, useRef, KeyboardEvent, useState, MouseEvent,
} from 'react';
import FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import './resultComponent.scss';
import ResultTableBody from './ResultTableBody';
import createHtmlDownloadFile from '../../utils/createHtmlDownloadFile';
import handleResultTableFocus from '../../utils/handleResultTableFocus';
import { IResultTable } from '../../ìnterfaces/ResultTable';
import { DEFAULT_APPLICATION_TEXT, LANGUAGE } from '../../constants/constants';

class JsPDF extends jsPDF { }

/**
 * Create the result page with the content from completedHtml tag of the SurveyJS JSON model adn the result table
 *
 * @param props
 * @returns
 */
function ResultComponent(props: { html: string, audience: string, result: IResultTable }): ReactElement {
  const { html, audience, result } = props;
  const { trackEvent } = useMatomo();
  const {
    BTN_SAVE_HTML, BTN_SAVE_PDF, BTN_RELOAD_TEST,
    DOWNLOAD_HTML_FILE_NAME, DOWNLOAD_PDF_FILE_NAME,
    DOWNLOAD_HTML_TITLE_NAME, DOWNLOAD_PDF_TITLE_NAME,
  } = DEFAULT_APPLICATION_TEXT[LANGUAGE];

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      result.rows.forEach((r) => {
        const te = {
          action: 'Click',
          category: 'CGL Test',
          name: `${r.title} (${audience})`,
        };
        trackEvent(te);
      });
    }
  }, [trackEvent, audience, result]);
  const {
    tableHeading1, tableHeading2, tableHeading3, tableHeading4, rows,
  } = result;

  const tableRef = useRef<HTMLTableElement>(null);
  const pageResultRef = useRef<HTMLDivElement>(null);
  const [cellIndex, setCellIndex] = useState(1);
  const [rowIndex, setRowIndex] = useState(1);

  /**
   * Move the focus to the next element and update the table index state
   *
   * @param cell
   * @param cIndex
   * @param rIndex
  */
  const moveTo = (cell: HTMLTableDataCellElement, cIndex: number, rIndex: number) => {
    setRowIndex(rIndex);
    setCellIndex(cIndex);
    handleResultTableFocus(cell, cIndex, tableRef);
  };

  /**
   * Handle the onclick event on a table cell and update the table index state en set the focus on the element
   *
   * @param event
   */
  const onClickHandlerTableCell = (event: MouseEvent<HTMLTableCellElement>) => {
    setCellIndex(event.currentTarget.cellIndex);
    setRowIndex((event.currentTarget.parentNode as HTMLTableRowElement).rowIndex);
    handleResultTableFocus(event.currentTarget, event.currentTarget.cellIndex, tableRef);
  };

  /**
   * Handle the navigation and focus the cells for the result table with the role grid, so it's conform WCAG 2.1
   * - [Table with two header conform WCAG 2.1]{@link https://www.w3.org/WAI/tutorials/tables/two-headers}
   * - [Table with role grid conform WCAG 2.1] {@link https://www.w3.org/TR/wai-aria-practices/examples/grid/dataGrids.html}
   *
   * All the navigation keys:
   * - on "arrow right" Moves focus one cell to the right. If focus is on the right-most cell in the row, focus does not move
   * - on "arrow left" Moves focus one cell to the left. If focus is on the left-most cell in the row, focus does not move
   * - on "arrow down" Moves focus one cell down. If focus is on the bottom cell in the column, focus does not move
   * - on "arrow up" Moves focus one cell Up. If focus is on the top cell in the column, focus does not move
   * - on "page down" Moves focus down an author-determined number of rows, typically scrolling so the bottom row in the currently visible set of rows becomes one of the first visible rows. If focus is in the last row of the grid, focus does not move
   * - on "page up" Moves focus up an author-determined number of rows, typically scrolling so the top row in the currently visible set of rows becomes one of the last visible rows. If focus is in the first row of the grid, focus does not move
   * - on "home + ctrl" Moves focus to the first cell in the first row
   * - on "home" Moves focus to the first cell in the row that contains focus
   * - on "end + ctrl" Moves focus to the last cell in the last row
   * - on "end" Moves focus to the last cell in the row that contains focus
   *
   * @param event
   */
  const onKeyDownHandler = (event: KeyboardEvent<HTMLTableElement>) => {
    const tableElement = event.currentTarget;
    const tableRows = tableElement.rows;
    const { key, ctrlKey } = event;

    if (key === 'ArrowRight' && tableRows[rowIndex].cells.length - 1 > cellIndex) {
      event.preventDefault();

      const row = tableRows[rowIndex];
      const cell = row.cells[cellIndex + 1];
      moveTo(cell, cellIndex + 1, rowIndex);
    }

    if (key === 'ArrowLeft' && cellIndex > 0) {
      event.preventDefault();

      const row = tableRows[rowIndex];
      const cell = row.cells[cellIndex - 1];
      moveTo(cell, cellIndex - 1, rowIndex);
    }

    if (key === 'ArrowDown' && tableRows.length - 1 > rowIndex) {
      event.preventDefault();

      const row = tableRows[rowIndex + 1];
      const cell = row.cells[cellIndex];
      moveTo(cell, cellIndex, rowIndex + 1);
    }

    if (key === 'ArrowUp' && rowIndex > 1) {
      event.preventDefault();

      const row = tableRows[rowIndex - 1];
      const cell = row.cells[cellIndex];
      moveTo(cell, cellIndex, rowIndex - 1);
    }

    if (key === 'PageDown') {
      event.preventDefault();

      const row = tableRows[tableRows.length - 1];
      const cell = row.cells[cellIndex];
      moveTo(cell, cellIndex, tableRows.length - 1);
    }

    if (key === 'PageUp') {
      event.preventDefault();

      const row = tableRows[1];
      const cell = row.cells[cellIndex];
      moveTo(cell, cellIndex, 1);
    }

    if (key === 'Home' && ctrlKey) {
      event.preventDefault();

      const row = tableRows[1];
      const cell = row.cells[0];
      moveTo(cell, 0, 1);
    }

    if (key === 'Home' && !ctrlKey) {
      event.preventDefault();

      const row = tableRows[rowIndex];
      const cell = row.cells[0];
      moveTo(cell, 0, rowIndex);
    }

    if (key === 'End' && ctrlKey) {
      event.preventDefault();

      const row = tableRows[tableRows.length - 1];
      const cell = row.cells[row.cells.length - 1];
      moveTo(cell, row.cells.length - 1, tableRows.length - 1);
    }

    if (key === 'End' && !ctrlKey) {
      event.preventDefault();

      const row = tableRows[rowIndex];
      const cell = row.cells[row.cells.length - 1];
      moveTo(cell, row.cells.length - 1, rowIndex);
    }
  };

  /**
   * Save the results of the test as a HTML page on the client-side.
   * The blob size should have a max of 500MiB to support all the browsers.
   * And with the current file size of 650 kb it isn't a problem.
   */
  const saveAsHtml = async () => {
    const htmlPageResult = document.querySelector('.page-result') as HTMLElement;
    const htmlSurveyElement = document.getElementById('survey') as HTMLElement;
    const htmlPageResultClone = htmlPageResult.cloneNode(true) as HTMLElement;
    // add the specific styling for GS or GK to the download file
    htmlPageResultClone.classList.add(htmlSurveyElement.className);

    const htmlDocument = await createHtmlDownloadFile(htmlPageResultClone);
    const blob = new Blob([htmlDocument], { type: 'text/html' });
    FileSaver.saveAs(blob, DOWNLOAD_HTML_FILE_NAME);
    if (process.env.NODE_ENV !== 'development') {
      result.rows.forEach((r) => {
        const te = {
          action: DOWNLOAD_HTML_TITLE_NAME,
          category: 'CGL Test',
          name: `${r.title} (${audience})`,
        };
        trackEvent(te);
      });
    }
  };

  const saveAsPdf = async () => {
    let { body } = document;
    if (body.querySelector('#resultContent')) {
      body = body.querySelector('#resultContent')!;
    }
    html2canvas(body).then((canvas) => {
      const pdf = new JsPDF();
      pdf.addImage(canvas, 'JPEG', 10, 10, 180, 160 + (result.rows.length * 10));
      pdf.save(DOWNLOAD_PDF_FILE_NAME);
    });
    if (process.env.NODE_ENV !== 'development') {
      result.rows.forEach((r) => {
        const te = {
          action: DOWNLOAD_PDF_TITLE_NAME,
          category: 'CGL Test',
          name: `${r.title} (${audience})`,
        };
        trackEvent(te);
      });
    }
  };

  const reloadSurvey = () => {
    window.location.reload();
  };

  /**
   * Perform side effects in function components.
   * In this way it handle async functionality
   */
  useEffect(() => {
    setTimeout(() => pageResultRef.current?.scrollIntoView(), 0);
  }, [pageResultRef]);

  return (
    <div data-testid="resultPage" className="page-result" ref={pageResultRef}>
      <div id="resultContent">
        <div className="table-result">
          <div className="table-responsive">
            <table
              data-testid="table"
              className="table c-table"
              role="grid"
              onKeyDown={onKeyDownHandler}
              ref={tableRef}
            >
              <thead className="c-table__thead">
                <tr>
                  <td tabIndex={-1} style={{ visibility: 'hidden' }} />
                  <th tabIndex={-1} scope="col">{ tableHeading1 }</th>
                  <th tabIndex={-1} scope="col">{ tableHeading2 }</th>
                  <th tabIndex={-1} scope="col">{ tableHeading3 }</th>
                  <th tabIndex={-1} scope="col">{ tableHeading4 }</th>
                </tr>
              </thead>
              <ResultTableBody rows={rows} onClickHandler={onClickHandlerTableCell} />
            </table>
          </div>
          <div className="btn-row text-right">
            <button data-testid="btn-reload" className="btn btn--grey" type="button" onClick={reloadSurvey}>
              { BTN_RELOAD_TEST }
            </button>
            <button
              aria-label="Opslaan als PDF, deze versie is niet toegankelijk. Opslaan als HTML is het toegankelijke alternatief."
              data-testid="btn-save-pdf"
              className="btn btn--grey"
              type="button"
              onClick={saveAsPdf}
            >
              <span className="icon file-type-icon-file-pdf-o">{ BTN_SAVE_PDF }</span>
            </button>
            <button data-testid="btn-save-html" className="btn btn--grey" type="button" onClick={saveAsHtml}>
              <span className="icon file-type-icon-file-code-o">{ BTN_SAVE_HTML }</span>
            </button>
          </div>
        </div>
        <div data-testid="completed-html" className="completed-html" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  );
}

export default ResultComponent;
