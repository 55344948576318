/**
 * Check if element has the attribute and remove it
 *
 * @param element html element
 */
const accessibleRemoveAttributeByName = (element: HTMLElement | null, attributeName:string): void => {
  if (element && element.hasAttribute(attributeName)) {
    element.removeAttribute(attributeName);
  }
};

export default accessibleRemoveAttributeByName;
