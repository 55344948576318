import { SurveyModel } from 'survey-react';
import { IOnAfterRenderQuestionOptions } from '../ìnterfaces/OnAfterRenderQuestionOptions';
import accessibleCheckbox from './accessibleCheckbox';
import accessibleDescription from './accessibleDescription';
import accessibleRadioGroup from './accessibleRadioGroup';
import accessibleRequiredErrorText from './accessibleRequiredErrorText';
import toggleShowHideQuestionDescription from './toggleShowHideQuestionDescription';

/**
 * This function is bind to special SurveyJS event, the event is fired after question is rendered in DOM.
 * This event is to modify the HTML element [after rendering of the survey question]{@link https://surveyjs.io/Documentation/Library/?id=surveymodel#onAfterRenderQuestion}.
 * The HTML is only modified in such a way if the location of the description has the value 'default'.
 *
 * @param sender
 * @param options
 * @returns
 */
const onAfterRenderQuestionHandler = (sender: SurveyModel, options: IOnAfterRenderQuestionOptions): void => {
  const { htmlElement, question } = options;
  const { description, descriptionLocation, propertyHash } = question;
  const { id } = propertyHash;

  // make all the required error text for all the questions conform WCAG 2.1
  accessibleRequiredErrorText(question);

  // make all the required error text for all the questions conform WCAG 2.1
  accessibleRequiredErrorText(question);

  // make checkbox conform WCAG 2.1
  if (question.getType() === 'checkbox') {
    accessibleCheckbox(htmlElement);
  }

  // make radiogroup conform WCAG 2.1
  if (question.getType() === 'radiogroup') {
    const ariaDescribedbyId = description && descriptionLocation === 'default' ? id : undefined;
    accessibleRadioGroup(htmlElement, ariaDescribedbyId);
  }

  // Toggle show hide description on collapse button
  if (description) {
    if (descriptionLocation === 'default') {
      toggleShowHideQuestionDescription(htmlElement, id, description);
    } else {
      accessibleDescription(htmlElement, id);
    }
  }
};

export default onAfterRenderQuestionHandler;
