import React, { ReactElement } from 'react';
import { DEFAULT_APPLICATION_TEXT, LANGUAGE } from '../../constants/constants';
import { IResultTableRowsProps } from '../../ìnterfaces/ResultTableRowsProps';
import ResultTableDataCell from './ResultTableDataCell';

/**
 * The row of an result table and contains header cell and data cell(s)
 *
 * @param props
 * @returns
 */
function ResultTableRows(props: IResultTableRowsProps): ReactElement {
  const { rowIndex, row, onClickHandler } = props;
  const {
    img, items, link, name, title,
  } = row;

  const tableDataCells = items.map((item, index) => {
    // it's not possible to have more than 4 data cells, because there are only 4 columns
    if (index <= 3) {
      return <ResultTableDataCell cell={item} key={item.name} onClickHandler={onClickHandler} />;
    }
    return false;
  });

  return (
    <tr data-testid="tr" role="row">
      <th scope="row" onClick={onClickHandler}>
        <a
          href={link}
          tabIndex={rowIndex === 0 ? 0 : -1}
          target="_blank"
          rel="noreferrer"
          className="c-table__link d-flex"
        >
          <img src={img} alt={name} className="c-table__img align-self-center" />
          <span className="c-table__link__text flex-grow-1">{ title }</span>
          <span className="sr-only">{ DEFAULT_APPLICATION_TEXT[LANGUAGE].EXTERNAL_LINK_SR_ONLY }</span>
        </a>
      </th>
      { tableDataCells }
    </tr>
  );
}

export default ResultTableRows;
